body {
  font-family: maven, helvetica, arial, sans-serif;
  font-size: $base-font-size;
}
.pink {
  color: $llr-pink;
}

h1 {
    //font-family: steelfish, helvetica, arial, sans-serif;
    //font-size: 48px;
    //text-transform: uppercase;
    //letter-spacing: 1px;
    color: $llr-pink;
    font-weight: normal;
}

h6 {
  color: $llr-grey-lt;
  text-transform: uppercase;
}


.disclaimer {
  font-size: 12px;
}


label {
  font-weight: bold;
}


a {
    color: $link-color;
    //&:visited { color: inherit; }
    &:hover { color: lighten($link-color, 10%);}
    &:active { color: darken($link-color, 10%);}
}


.field-validation-error {
  font-size: .8em;
  color: red;
}

.text-mono {
    font-family: monospace;
}
