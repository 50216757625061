@font-face {
    font-family: 'museosans';
    src: url('//d1mr75gb4rthqg.cloudfront.net/fonts/museosans500.woff2') format('woff2'),
         url('//d1mr75gb4rthqg.cloudfront.net/fonts/museosans500.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'maven';
    src: url('//d1mr75gb4rthqg.cloudfront.net/fonts/mavenpro-regular.eot');
    src: url('//d1mr75gb4rthqg.cloudfront.net/fonts/mavenpro-regular.eot?#iefix') format('embedded-opentype'),
         url('//d1mr75gb4rthqg.cloudfront.net/fonts/mavenpro-regular.woff2') format('woff2'),
         url('//d1mr75gb4rthqg.cloudfront.net/fonts/mavenpro-regular.woff') format('woff'),
         url('//d1mr75gb4rthqg.cloudfront.net/fonts/mavenpro-regular.ttf') format('truetype'),
         url('//d1mr75gb4rthqg.cloudfront.net/fonts/mavenpro-regular.svg#maven') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'maven';
    src: url('//d1mr75gb4rthqg.cloudfront.net/fonts/mavenpro-medium.eot');
    src: url('//d1mr75gb4rthqg.cloudfront.net/fonts/mavenpro-medium.eot?#iefix') format('embedded-opentype'),
         url('//d1mr75gb4rthqg.cloudfront.net/fonts/mavenpro-medium.woff2') format('woff2'),
         url('//d1mr75gb4rthqg.cloudfront.net/fonts/mavenpro-medium.woff') format('woff'),
         url('//d1mr75gb4rthqg.cloudfront.net/fonts/mavenpro-medium.ttf') format('truetype'),
         url('//d1mr75gb4rthqg.cloudfront.net/fonts/mavenpro-medium.svg#maven') format('svg');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'steelfish';
    src: url('//d1mr75gb4rthqg.cloudfront.net/fonts/steelfish.woff2') format('woff2'),
         url('//d1mr75gb4rthqg.cloudfront.net/fonts/steelfish.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'Glyphicons Halflings';

  src: url('//d1mr75gb4rthqg.cloudfront.net/fonts/glyphicons-halflings-regular.eot');
  src: url('.//d1mr75gb4rthqg.cloudfront.net/fonts/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'),
        url('//d1mr75gb4rthqg.cloudfront.net/fonts/glyphicons-halflings-regular.woff2') format('woff2'),
        url('//d1mr75gb4rthqg.cloudfront.net/fonts/glyphicons-halflings-regular.woff') format('woff'),
        url('//d1mr75gb4rthqg.cloudfront.net/fonts/glyphicons-halflings-regular.ttf') format('truetype'),
        url('//d1mr75gb4rthqg.cloudfront.net/fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular') format('svg');
}
