.page-customers {
.customer-table--firstname {
  width: 20%;
}
.customer-table--email {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.customer-table--phone {
  width: 150px;
}
.table-container {
  width: 100%;
  overflow-x: scroll;
}


.customer-table--email-header {
  max-width: 400px;
}


#tableBody {
  width: 100%;
}

//.col-id { min-width: 50px; }
/*.col-firstname, .col-lastname, .col-phone { width: 200px; }*/
.col-edit { width: 95px; }
//.col-email { max-width: 400px;}
table.table { width: 100%; max-width: 100% !important; table-layout: fixed;}

a.dt-button {
  &:hover {
    color: #666 !important;
  }
}


.grid-container {
  display: grid;
  height: 100%;
  grid-gap: 24px;
  padding: 24px;

  grid-template-areas: 
      "header header"
      "sidebar content";
  grid-template-columns: 350px 1fr;
}

.customer-header {
  grid-area: header;
  display: grid;
  grid-template-columns: minmax(350px, auto) 1fr 1fr 1fr;
  grid-gap: 16px;
  grid-template-rows: 1fr;
  align-items: end; 
}

.customer-header p, .customer-header h1 {
  margin: 0;
}



.customer-menu {
  font-size: 12px;
  display: block;
  margin: 0;
  margin-bottom: 4px;
  color: $llr-blue;
}

.sidebar {
  grid-area: sidebar;
  display: grid;
  grid-gap: 24px;  
  max-width: 350px; 
}

.content { 
  grid-area: content;
  display: grid;
  grid-gap: 48px;
  grid-template-rows: auto 1fr;
}


.favs {
  ul {
    list-style: none;
    padding: 0;
  }
}

.notes {
  form {
    margin: 0;
  }
  p{

  
  a { visibility: hidden; color: $llr-blue; text-decoration: underline;}
  &:hover a { visibility: visible;}
  }

}

.recentorders {
  //grid-area: recentorders;
}

.customer-orders, .customer-rewards{
  list-style: none;
  margin: 0;
  padding: 0;

  p.zero-state {
    margin: 16px 8px;
    text-align: center;
    font-style: italic;
  }
}
.customer-order {
  display: grid;
  grid-gap: 16px;
  //grid-template-areas: "number pop-up date status amount"
  grid-template-columns: 150px 1fr 150px 130px 130px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(0,0,0,.2);

  &:hover {
    text-decoration: none;
    background-color: lighten($llr-blue, 25%);
  }
}
.customer-order--header, .customer-reward--header {
  font-weight: bold;
  padding-bottom: 4px;
}

.customer-order__number {
  //grid-area: number;
  width: 150px;
  padding-left: 8px;
}
.customer-order span:first-child, .customer-reward span:first-child {
  padding-left: 8px;
}

.customer-order span:last-child, .customer-reward span:last-child {
  padding-right: 8px;
  text-align: right;
}

.customer-order__popup {
  //grid-area: pop-up;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
}
.customer-order__date {
  //grid-area: date;
}
.customer-order__status {
  //grid-area: status;
}
.customer-order__amount {
  //grid-area: amount;
  text-align: right;
  padding-right: 8px;
}



.rewardhistory {
 // grid-area: rewardhistory;
}

.customer-reward {
  display: grid;
  grid-gap: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(0,0,0,.2);
  grid-template-columns: 1fr 2fr 1fr 1fr;
}

.sidebar-section {
  background: #f2f2f2;
  padding: 24px;

  h2{
    color: $llr-pink;
    text-transform: uppercase;
    font-size: 20px;
  }
}

}
