.llr-combobox {

  span { margin-right: 0;}

  .dd {
    width: auto !important;

    &:focus {
      outline: none;
    }
  }

  .borderRadius {
    border-radius: 0;
  }

  .shadow {
    -webkit-box-shadow: none;
            box-shadow: none;
  }


  .ddTitle {
    background: #fff;
  }

  .borderRadiusTp {
    border-radius: 0;
  }

  span.description {
    color: $llr-grey !important;
    font-size: 11px;
    width: 100%;
    text-overflow: ellipsis;
    overflow-x: hidden;
  }

  .ddChild {
    height: auto !important;
    max-height: 400px !important;
  }

  .ddTitleText {
    margin-right: 20px;
  }

  .ddArrow {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid $llr-grey;

    margin-top: -2px;
    background: none;
    right: 8px;
  }
}


.customer-info h3, .customer-info p {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
