html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
body {
  padding-bottom: 25px;
  //background-color: $body-background;
}

.page-title {
  //font-family: steelfish;
  //text-transform: uppercase;
  color: $llr-pink;
}
.create-order-buttons {
  form {
    display: inline;
    margin-right: 30px;
    font-size: 14px !important;
    font-family: museosans, verdana, sans-serif;
  }
  .custom-combobox {
    height: 34px;
  }
  .custom-combobox-input {
    height: 34px;
  }
  span {
    margin-right: 20px;
  }
}

.orders-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 20px;

  h2 {
    /*  -webkit-box-flex: 1;*/
    /* -ms-flex-positive: 1;*/
    /*         flex-grow: 1;*/
    display: inline-block;
    margin-right: 50px;
  }
}

/* 100% Heights for AddOrder
------------------------------------*/
html,
body {
  height: 100%;
  min-height: 100%;
}

.wrap,
#main-instore {
  height: 100%;
}

.admin-container {
  height: 100%;
  height: calc(100% - 63px);
}

.hidden {
  display: none !important;
}
