$color-Dashboard: $llr-yellow;
$color-Inventory: $llr-pink;
$color-Content: $llr-blue;
$color-Email: $llr-green;
$color-Popups: $llr-orange;
$color-Reports: $llr-fuschia;
$color-Tasks: $llr-green;
$color-Account: $llr-grey;

.nav-container {
  border-bottom: 5px solid $llr-pink;
  background-color: #fff;
}

.navbar-brand {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.brand-text {
  font-family: steelfish;
  font-size: 22px;
  -webkit-font-smoothing: antialiased;
  color: $llr-pink;
  display: inline-block;
  margin-left: 7px;
}
.nav--dashboard a { color: $color-Dashboard; }
.nav--inventory a { color: $color-Inventory; }
.nav--content a   { color: $color-Content; }
.nav--email a     { color: $color-Email; }
.nav--popups a    { color: $color-Popups; }
.nav--reports a   { color: $color-Reports; }
.nav--tasks a     { color: $color-Tasks; }
.nav--account a   { color: $color-Account; }

ul.rainbow-nav > li {
    &:nth-child(7n+1) a { color: $llr-yellow }
    &:nth-child(7n+2) a { color: $llr-orange }
    &:nth-child(7n+3) a { color: $llr-pink }
    &:nth-child(7n+4) a { color: $llr-fuschia }
    &:nth-child(7n+5) a { color: $llr-purple }
    &:nth-child(7n+6) a { color: $llr-blue }
    &:nth-child(7n+7) a { color: $llr-green }
}

.nav {
  a {
    width: max-content;
  }
}

.nav:nth-child(7n+1) a { color: $llr-yellow; }
.nav:nth-child(7n+2) a { color: $llr-orange; }
.nav:nth-child(7n+3) a { color: $llr-pink; }
.nav:nth-child(7n+4) a { color: $llr-fuschia; }
.nav:nth-child(7n+5) a { color: $llr-purple; }
.nav:nth-child(7n+6) a { color: $llr-blue; }
.nav:nth-child(7n+7) a { color: $llr-green; }

// @mixin nav-links($bg) {
//   color: $bg;
//
//   &:hover, &:focus {
//     color: darken($bg, 15%);
//     -webkit-transition: all 0.3s ease;
//     transition: all 0.3s ease;
//   }
//   &:active, &:active:focus {
//     color: darken($bg,25%);
//   }
//   .selected &{
//     color: darken($bg, 25%);
//   }
// }
//
//
// .navbar-main {
//   background-color: #fff;
//   border: 0;
//   border-top: 5px solid $llr-pink;
//   border-radius: 0;
//
// .navbar-brand img {
//   max-width: 150px;
// }
// .navbar-nav li {
//   font-family: 'steelfish', verdana, helvetica, arial, sans-serif;
//   text-transform: uppercase;
//   font-size: 20px;
//   -webkit-font-smoothing: antialiased;
//   padding: 0 !important;
//   padding-top: 8px !important;
//   position: relative;
//
//   a {
//     text-decoration: none;
//   }
//
//   &::before {
//   content: '';
//   position: absolute;
// 	bottom: 0;
// 	left: 0;
// 	width: 50%;
// 	height: 3px;
//   margin-left: 15px;
//   -webkit-transform: scale3d(0, 1, 1);
// 	transform: scale3d(0, 1, 1);
// 	-webkit-transform-origin: center left;
// 	transform-origin: center left;
// 	-webkit-transition: transform 0.4s cubic-bezier(0.22, 0.61, 0.36, 1);
// 	-webkit-transition: -webkit-transform 0.4s cubic-bezier(0.22, 0.61, 0.36, 1);
// 	transition: -webkit-transform 0.4s cubic-bezier(0.22, 0.61, 0.36, 1);
// 	transition: transform 0.4s cubic-bezier(0.22, 0.61, 0.36, 1);
// 	transition: transform 0.4s cubic-bezier(0.22, 0.61, 0.36, 1), -webkit-transform 0.4s cubic-bezier(0.22, 0.61, 0.36, 1);
//   }
//
//   &:hover::before {
//     -webkit-transform: scale3d(1, 1, 1);
//     transform: scale3d(1, 1, 1);
//     -webkit-transition-delay: 0s;
//     transition-delay: 0s;
//     background-color: $llr-grey;
//   }
//
//   &.selected::before {
//     -webkit-transform: scale3d(1, 1, 1);
//     transform: scale3d(1, 1, 1);
//     -webkit-transition-delay: 0s;
//     transition-delay: 0s;
//     background-color: $llr-grey;
//   }
//
//   &.nav--dashboard  a{ @include nav-links($color-Dashboard); }
//   &.nav--inventory a{ @include nav-links($color-Inventory); }
//   &.nav--content a{ @include nav-links($color-Content); }
//   &.nav--email a{ @include nav-links($color-Email); }
//   &.nav--popups a { @include nav-links($color-Popups); }
//   &.nav--reports a { @include nav-links($color-Reports); }
//   &.nav--tasks a { @include nav-links($color-Tasks); }
//   &.nav--account a { @include nav-links($color-Account); }
// }
// }
// .navbar
// .badge {
//   margin-right: 10px;
//   font-family: museosans, verdana, helvetica, arial, sans-serif;
//   font-weight: bold;
//   font-size: 14px;
//   margin-top:-4px;
//   background-color: $llr-pink;
// }
//
//
//
//
// .menu--shylock .menu__link {
// 	position: relative;
// 	margin: 0 1em;
// 	padding-right: 0;
// 	padding-left: 0;
// 	color: #b5b5b5;
// 	-webkit-transition: color 0.4s;
// 	transition: color 0.4s;
// }
//
// .menu--shylock .menu__item--current .menu__link,
// .menu--shylock .menu__item--current .menu__link:hover,
// .menu--shylock .menu__item--current .menu__link:focus {
// 	color: #d94f5c;
// }
//
// .menu--shylock .menu__item--current .menu__link::after,
// .menu--shylock .menu__item--current .menu__link::before {
// 	-webkit-transform: scale3d(1, 1, 1);
// 	transform: scale3d(1, 1, 1);
// }
//
// .menu--shylock .menu__item--current .menu__link::before {
// 	-webkit-transition-delay: 0s;
// 	transition-delay: 0s;
// }
//
// .menu--shylock .menu__link:hover,
// .menu--shylock .menu__link:focus {
// 	color: #b5b5b5;
// }
//
// .menu--shylock .menu__link:hover::before,
// .menu--shylock .menu__link:focus::before {
// 	-webkit-transform: scale3d(1, 1, 1);
// 	transform: scale3d(1, 1, 1);
// 	-webkit-transition-delay: 0s;
// 	transition-delay: 0s;
// }
//
// .menu--shylock .menu__link::before,
// .menu--shylock .menu__link::after {
// 	content: '';
// 	position: absolute;
// 	bottom: 0;
// 	left: 0;
// 	width: 100%;
// 	height: 3px;
// 	-webkit-transform: scale3d(0, 1, 1);
// 	transform: scale3d(0, 1, 1);
// 	-webkit-transform-origin: center left;
// 	transform-origin: center left;
// 	-webkit-transition: transform 0.4s cubic-bezier(0.22, 0.61, 0.36, 1);
// 	-webkit-transition: -webkit-transform 0.4s cubic-bezier(0.22, 0.61, 0.36, 1);
// 	transition: -webkit-transform 0.4s cubic-bezier(0.22, 0.61, 0.36, 1);
// 	transition: transform 0.4s cubic-bezier(0.22, 0.61, 0.36, 1);
// 	transition: transform 0.4s cubic-bezier(0.22, 0.61, 0.36, 1), -webkit-transform 0.4s cubic-bezier(0.22, 0.61, 0.36, 1);
// }
//
// .menu--shylock .menu__link::before {
// 	background: #b5b5b5;
// 	-webkit-transition-delay: 0.4s;
// 	transition-delay: 0.4s;
// }
//
// .menu--shylock .menu__link::after {
// 	background: #d94f5c;
// }


.sidebar-navigation {
  margin-top: 10px;
  background: #f2f2f2;
  padding: 20px;
  min-width: 200px;
  max-width: 200px;

  font-family: museosans, verdana;

  a {
    color: #cb82b7;

    &.active {
      color: #64ccc9;
      &:visited {
        color: #64ccc9;
      }
    }

    &:visited {
      color: #cb82b7;
    }
  }

  h4 {
    font-size: 18px;
    line-height: 22px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 4px;
    color: #4d4d4d;
  }

  ul {
    list-style: none;
    padding: 0;
    margin:0;
  }

  li {
    padding: 3px 0;
    font-size: 14px;

  }
}
