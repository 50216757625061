.card {
  -webkit-box-shadow: 0 1px 2px rgba(0,0,0,0.07);
          box-shadow: 0 1px 2px rgba(0,0,0,0.07);
  padding: 20px;
  background: #fff;
  border-radius: 2px;
  border: 0;
  -webkit-transition: -webkit-box-shadow .5s;
  transition: -webkit-box-shadow .5s;
  transition: box-shadow .5s;
  transition: box-shadow .5s, -webkit-box-shadow .5s;

}

.card:hover {
  -webkit-box-shadow: 0 0 20px rgba(0,0,0,0.14);
          box-shadow: 0 0 20px rgba(0,0,0,0.14);
          -webkit-transition: -webkit-box-shadow .2s;
          transition: -webkit-box-shadow .2s;
          transition: box-shadow .2s;
          transition: box-shadow .2s, -webkit-box-shadow .2s;
}
