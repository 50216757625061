$body-background: #f4f4f4;

/* Colors */
$llr-yellow: #fed141;
$llr-yellow-lt: lighten($llr-yellow, 10%);
$llr-yellow-dk: darken($llr-yellow, 10%);

$llr-orange: #ff9d6e;
$llr-orange-lt: lighten($llr-orange, 10%);
$llr-orange-dk: darken($llr-orange, 10%);

$llr-pink: #f67599;
$llr-pink-lt: lighten($llr-pink, 5%);
$llr-pink-dk: darken($llr-pink, 10%);

$llr-fuschia: #dd7fd3;
$llr-fuschia-lt: lighten($llr-fuschia, 10%);
$llr-fuschia-dk: darken($llr-fuschia, 10%);

$llr-purple: #9595d2;
$llr-purple-lt: lighten($llr-purple, 10%);
$llr-purple-dk: darken($llr-purple, 10%);

$llr-blue: #8bb8e8;
$llr-blue-lt: lighten($llr-blue, 10%);
$llr-blue-dk: darken($llr-blue, 10%);

$llr-green: #64ccc9;
$llr-green-lt: lighten($llr-green, 10%);
$llr-green-dk: darken($llr-green, 10%);

$llr-grey: #888b8d;
$llr-grey-lt: lighten($llr-grey, 10%);
$llr-grey-dk: darken($llr-grey, 10%);



$link-color: $llr-blue;


//Modals
$modal-background-color: rgba(0,0,0,.4);
$modal-content-bg: #fff;


//navigation
$color-Dashboard: $llr-yellow;
$color-Inventory: $llr-pink;
$color-Content: $llr-blue;
$color-Email: $llr-green;
$color-Popups: $llr-orange;
$color-Reports: $llr-fuschia;
$color-Tasks: $llr-green;
$color-Account: $llr-grey;
