@mixin button-bg($bg, $text) {
  background: $bg;
  color: #fff;
  border-color: darken($bg, 8%);

  &:hover, &:focus {
    background:darken($bg,8%);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    color: #fff;
  }
  &:active, &:active:focus {
    background:darken($bg,25%);
    color: #fff;
    outline: none;
    box-shadow: none;
  }

  &:focus {
    outline: none;
  }
}

.btn, a.btn {
  @include button-bg(#fff, #333);
  color: #333;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  //padding: 8px 30px;
  text-align: center;
  font-weight: 300;
  border: 1px solid rgba(0,0,0,.15);

  &:active:focus,
  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }

  &.active { background-color: #eee; }
}

.btn-default {
  border: 1px solid #ccc;
  color: #333;

  &:hover {
    color: #333 !important;
  }
}

.btn-round {
  border-radius: 20px;
}



.btn-yellow, a.btn-yellow  {  @include button-bg($llr-yellow, #000)}
.btn-orange, a.btn-orange  {  @include button-bg($llr-orange, #fff)}
.btn-pink, a.btn-pink    {  @include button-bg($llr-pink, #fff) }
.btn-fuschia, a.btn-fuschia {  @include button-bg($llr-fuschia, #fff)}
.btn-purple, a.btn-purple  {  @include button-bg($llr-purple, #fff)}
.btn-blue, a.btn-blue    {  @include button-bg($llr-blue, #fff)}
.btn-green, a.btn-green   {  @include button-bg($llr-green, #fff)}


.btn-right {
  float: right;
}

.btn-primary {
  @include button-bg(darken($llr-blue, 25%), #fff);
}


.btn-danger, a.btn-danger {
    color: #fff;
    @include button-bg(#dc3545, #fff);
    background-color: #dc3545 !important;
    border-color: #dc3545 !important;
}

.btn-link {
  border-color: transparent;
  box-shadow: none;
  background-color: transparent;
  color: $llr-grey-lt;

  &:hover, &:focus {
    background-color: transparent;
    color: $llr-grey-dk;
  }
}

.list-group-item {
  .button-container { visibility: hidden; }
  &:hover {
    .button-container { visibility: visible; }
  }
}

/* .discount-btn-group {*/
/*   .btn-default.active {*/
/*     @include button-bg(darken($llr-blue, 25%));*/
/*   }*/
/* }*/
