.getpaid {
  margin-top: 50px;

  .unbatchedorders-list {
    padding: 0;

    li {
      display: flex;
      flex-direction: row;
      flex-flow: wrap;
      padding: 10px;
      border: 1px solid #ccc;

      span {
        width: 50%;
      }

      .order-amount, .order-status {
        text-align: right;
      }
      .order-id, .order-status {
        font-size: .75em;
        color: #999;
      }

    }
  }

  .batches {
    padding: 0;

    li {
      list-style: none;
      border-bottom: 1px solid #ccc;
      padding-bottom: 5px;
    }
  }
}
.payments-list a:visited {
  color: #495057;
}
