﻿.llr-combobox {
  display: inline;
}

.custom-combobox-input {
  background: #fff;
  border-color: $llr-grey-lt;
  font-size: 14px !important;
}

.custom-combobox-toggle {
  background: #fff;
  border-color: $llr-grey-lt;
}

// input[type="text"],
// input[type="password"],
// input[type="email"],
// input[type="tel"] {
//   border: 1px solid lighten($llr-grey, 10%);
//   border-radius: 4px;
//   width: 100%;
//   height: 40px;
//   //margin-top: 10px;
//   margin-bottom: 20px;
//   padding: 5px;
//   font-size: $base-font-size;
//
//   &.input-validation-error {
//     border-color: red;
//
//     &::-webkit-input-placeholder { color: red;}
//     &:-ms-input-placeholder { color: red;}
//     &::placeholder { color: red;}
//   }
//
// }
