.subtitle {
  font-style: italic;
  color: $llr-grey-lt;
  font-size: 18px;
  margin-bottom: 40px;
}


.tips-page {
  .card {
    padding: 0;
    box-shadow: none;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
    margin-bottom: 40px;
  }

  .card-header {
    font-family: steelfish;
    font-size: 24px;
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #fff;
  }

  .card-body {
    padding: 20px;
  }
}


.card-header__mobile {
  background-color: $llr-yellow;
}

.card-header__web {
  background-color: $llr-orange;
}
