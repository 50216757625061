/* Shame.scss */

.custom-combobox-toggle {
  z-index: 2 !important;
}


.popup-details {
  h1 { margin-top: 0;}
  h6 { margin-bottom: 0;}
}

.carousel-control {
  width: 30px;
}

.carousel-control.left, .carousel-control.right {
  background-image: none;
  -webkit-filter: none;
          filter: none;
}

.glyphicon {
  color: $llr-pink;
}
.btn .glyphicon {
  color: inherit;
}



body {
  //background-color: #fff !important;
}
.container {
  /*padding-top: 50px;*/
}



.item-container {
  width: 90%;
  padding: 20px;
  margin: 10px;
  -webkit-box-shadow: 0 0 5px rgba(0,0,0,.05);
          box-shadow: 0 0 5px rgba(0,0,0,.05);

display: flex;
flex-direction: column;

.image-tag {
  flex-grow: 1;
}


  img { margin: 0 auto 20px;
    padding-bottom: 10px;
  border-bottom: 1px solid #ccc;}



}
.item-container:hover {
  -webkit-box-shadow: 0 0 20px rgba(0,0,0,.1);
          box-shadow: 0 0 20px rgba(0,0,0,.1);
}


.product-item {
  position: relative;
}

.product-item.in-cart {
  display: none;
}


.cart-item {
  width: 100%;
  /*background-color: #fff;*/
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
          margin-bottom: 10px;


  img {
    width: 40px;
  }

  .cart-item__img-container {
    width: 40px;
    margin-right: 10px;
  }
  .cart-item__name-container {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
            margin-right: 30px;
  }
  .cart-item__name {
    font-weight: bold;
    font-size: 16px;
  }

.cart-item__discount-container {

  label {
    font-size: 12px;
    color: $llr-grey;
  }
}
  .cart-item__discount-input {
    /* -webkit-box-flex: 1;*/
    /*     -ms-flex-positive: 1;*/
    /*         flex-grow: 1;*/
  }

}


.dollar {
  position: relative;
  /*visibility: hidden;*/
  &:before {

    content: '$';
    display: block;
    padding: 5px;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    visibility: visible;
  }

  .dollar:checked {
    &:before {
      content: '$';
      display: block;
      color: #fff;
      background-color: $llr-blue;
    }
  }
}

.screenlock {
  position: relative;
  &:before {
    content: '';
    z-index: 1000000;
    background-color: rgba(255,255,255,.8);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
  }
}


.customer-info {
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
  padding-bottom: 10px;
}



#cartContainer {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  overflow-y: scroll;
}

.btnAddItem {
  margin-left: auto;
  display: block;
}



.addorder-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.item-catalog {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  padding-left: 15px;
  padding-right: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.subtotal-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 300px;
  min-width: 300px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  padding: 20px 15px;
}

#itemSelectModalContent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  height: calc(100% - 56px);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
          -webkit-box-flex: 1;
              -ms-flex-positive: 1;
                  flex-grow: 1;

}

.product-container {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  overflow-y: scroll;
}

.product-item {
  width: 200px;
  float: left;
  margin: 10px;

     .item-container {
       border: 1px solid #eee;
       border-radius: 4px;
       min-height: 300px;
     }
}

.toolbar {
  margin-bottom: 20px;
}

.filter-input-group {
 display: inline-block;
 margin-right: 20px;

 label {
   display: block;
 }
  select {
    display: inline-block;
    margin-right: 10px;
    width: auto!important;
  }
}


.totals-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: baseline;
        -ms-flex-align: baseline;
            align-items: baseline;
            border-bottom: 1px solid #ccc;
            margin-bottom: 10px;

            label {
              -webkit-box-flex: 1;
                  -ms-flex-positive: 1;
                      flex-grow: 1;
            }

            input {
              text-align: right;
            }
            input[readonly] {
              border: 0;
              background: none;
            }
}

.popuplist {

  padding: 0;
  margin-bottom: 50px;

  li {
    list-style: none;
    list-style-image: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border: 1px solid #ccc;
    padding: 10px;
  }
  li + li {
  /*  border-top: none;*/
  margin-top: -1px;
  }
}

.popuplist-itemdetails {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.popup-description {
  font-family: museosans, verdana, helvetica, arial, sans-serif;
  font-size: 14px;
  color: $llr-blue;
/*  -webkit-font-smoothing: antialiased;*/
  a {
    color: $llr-blue;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

}

.popup-date {
  font-size: 10px;
  color: $llr-grey;
}

.popuplist-itemactions {
  color: $llr-blue;
  font-size: 12px;

  a {
    color: $llr-blue;
    text-decoration: none;
    padding-left: 2px;
    padding-right: 2px;

    &:hover {
      text-decoration: underline;
    }
  }
}
.title-withbutton {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
-webkit-box-align: center;
    -ms-flex-align: center;
        align-items: center;
}
.section-title {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;

  text-transform: uppercase;
  color: $llr-grey;
  font-size: 16px;
}


.orders-list-container {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.orders-list {
  margin-right: 40px;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;


  ul {
    padding: 0;

  }

  li {
    list-style: none;
    list-style-image: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border: 1px solid #ccc;
    background-color: #fff;
    padding: 10px;
  }
  li + li {
  /*  border-top: none;*/
  margin-top: -1px;
  }

  div:first-child {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
  }
  div:nth-child(2){
    text-align: right;
  }

}

.view-details {
  font-size: 11px;
  color: $llr-blue;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.orders-list + .orders-list {
  margin-right: 0;
}

.order-item__customer-name {
  font-size: 16px;
  display: block;
}

.order-item__order-number {
  font-size: 11px;
  color: $llr-grey;
}

.order-item__order-total {
  font-size: 16px;
  display: block;
}

.badge-Open {
  background-color: $llr-blue;
  text-transform: uppercase;
  font-size: 9px;
}

.badge-Complete {
  background-color: $llr-green;
  text-transform: uppercase;
  font-size: 9px;
}

.badge-Paid {
  background-color: $llr-orange;
  text-transform: uppercase;
  font-size: 9px;
}

.badge-Cancelled {
  background-color: $llr-grey;
  text-transform: uppercase;
  font-size: 9px;
}
.badge-Returned {
    background-color: $llr-purple;
    text-transform: uppercase;
    font-size: 9px;
}
.badge-NewCustomer {
    background-color: $llr-yellow;
    text-transform: none;
    font-size: 9px;
}
.badge-NewCustomerToLLR {
    background-color: $llr-pink;
    text-transform: none;
    font-size: 9px;
}

.order-details-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  width: 100%;
}

.order-items-container {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
          padding: 0;
          margin-right:40px;

          max-height: 600px;
          overflow-y: scroll;
}

.order-item {
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
          border-bottom: 1px solid #eee;
}

.order-item + .order-item {
  margin-top: -1px;
}

.order-item__item-image {
    width: 80px;
    padding-right: 10px;

    & img {
        width: 100%;
        height: auto;
    }
}

.order-item__item-name {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  font-size: 16px;
  margin: 0;
}

.order-item__item-total {
  font-size: 12px;

  p {margin: 0;}
}

.order-item__originalprice {
  color: $llr-grey;
  text-decoration: line-through;
}

.order-summary-container p {
  font-size: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
          min-width: 120px;
          border-bottom: 1px solid #eee;

  .order-item-summary__label {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
  }
}


.hidden-type, .hidden-style, .hidden-size {
  display: none;
}

#smalltext {
  color: $llr-grey;

}


.ui-autocomplete {
  max-height: 460px;
  overflow: scroll;
}



a { cursor: pointer !important;}
button { cursor: pointer !important;}

#tableHead, #tableFoot {
  width: 100%;
}
.dataTables_filter label{
  justify-content: flex-end;
  margin-bottom: 10px;
}
div.dataTables_filter input{
  border: 1px solid rgba(0,0,0,.15);
}



.table-overflow-fix {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-overflow-fix {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
