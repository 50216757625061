.main-container {
  padding-bottom: 100px;
}


.authentication {
  background: $llr-pink;
  background-image: linear-gradient(135deg, #FED141 0%, #FF9D6E 22%, #FE9972 25%, #F77896 48%, #F67599 51%, #DD7FD3 77%, #8BB8E8 100%);
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0;
  margin: 0;

  @include smallscreen {
    background: #fff;
    align-items: flex-start;
  }
}


.floating-container {
  background: #fff;
  padding: 50px 50px 20px 50px;
  border-radius: 10px;
  box-shadow: 0 0 150px 0 rgba(0,0,0,0.40), 0 0 0 10px rgba(255,255,255,.1);
  //max-width: 80%;

  @include smallscreen {
    box-shadow: none;
    padding: 20px;
    border: 0;
    border-radius: 0;
  }

  h1 {
    margin-top: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .customer-registration-form {
    min-width: 900px;
  }
}


.sign-in-container {
  width: 400px;

  form {
    text-align: center;
  }

  input[type="text"], input[type="password"] {
    border: 1px solid rgba(136,139,141,0.30);
    border-radius: 4px;
    width: 100%;
    height: 40px;
    margin-bottom: 20px;
    padding: 5px;
    text-align: center;
    font-family: maven;
    font-size: 16px;

  &.input-validation-error {
    border-color: red;

    &::-webkit-input-placeholder { color: red;}
    &:-ms-input-placeholder { color: red;}
    &::placeholder { color: red;}
  }

  }

.logo-container {
  font-family: steelfish;
  font-size: 36px;
  color: $llr-pink;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include smallscreen {
    flex-wrap: nowrap;
    span { white-space: nowrap;}
  }
}

.sign-in-button {
  background-color: $llr-pink;
  border: 0;
  border-radius: 20px;
  color: #fff;
  font-size: 20px;
  padding: 8px 30px;
  text-align: center;
  min-width: 180px;
  margin: 50px auto 30px;
  font-weight: 300;

  &:hover {
    background-color: $llr-pink-lt;
  }

  &:active {
    background-color: $llr-pink-dk;
  }
}

.forgot-password a {
  color: $llr-grey-lt;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.checkbox {
  color: $llr-grey;

  input[type="checkbox"]{
    margin-bottom:1px;
  }
}

}

input:-webkit-autofill {
  background-color: transparent !important;
}


.validation-error-container {
  border: 1px solid red;
  border-radius: 4px;
  padding: 8px 10px;
  width: 100%;
  margin-bottom: 20px;
  background-color: lighten(red, 45%);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  &:empty, &.validation-summary-valid {
    display: none;
  }
}
