.ordersfiltering {

  .form-group {
    margin-right: 24px;

    &:first-child {
      flex-grow: 1;
    }
    &:last-child{
      margin-right: 0;
    }

    label {
      display: block;
      white-space: nowrap;
    }

    .select100 {
        width: 100%;
    }
  }

  // Form Elements
  select {
    display: inline-block;
    width: auto;
  }


}


.tab-track {
  border-bottom: 1px solid #e9e9e9;
}

.btn-tab {
  border-bottom: 0;
  border-radius: 4px 4px 0 0;
  background: #e9e9e9;
  margin-bottom: -1px;
  padding: 4px 12px;
  border: 1px solid #e9e9e9;

  &:focus {
    outline: none;
  }

  &.active {
    border-bottom: #fff;
    background: #fff;
    //border-top: 1px solid $llr-blue;
    //box-shadow: inset 0 3px 0 $llr-blue;
  }
}


.orders-table {
  th a {
    color: #212529;
    &:hover {
      color: #212529;
      text-decoration: none;
    }
    &:visited {
      color: #212529;
    }
  }
}


// SHIPPING MANAGEMENT PAGE
.shippingmanagement {
  .form-group {
    margin-right: 24px;

    &:first-child {
      flex-grow: 1;
    }
    &:last-child{
      margin-right: 0;
    }

    label {
      display: block;
      white-space: nowrap;
    }

    .select100 {
        width: 100%;
    }
  }

  // Form Elements
  select {
    display: inline-block;
    width: auto;
  }

}
