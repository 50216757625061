.inventory-header {
  background-color: $llr-blue;
  padding: 20px;


  h4 {
    display: inline-block;
    color: #fff;
    margin-right: 40px;
  }

  a {
    color: #fff;
    margin-right: 20px;
  }
}

.inventory-subheader {
  background-color: $llr-blue-dk;
  padding: 10px 20px;
  margin-bottom: 20px;

  color: #fff;

  ul { margin: 0; padding: 0;}
  ul, li { list-style: none;}

  li { display: inline-block; margin-right: 20px;}
  a { color: #fff;}
}

.style-row > .col-md-4 {
  padding: 20px;

}
.style-card {
  min-height: 400px;
  margin-bottom: 20px;
  //min-height: 100%;
  position: relative;


  h3 {
    font-weight: bold;
    font-size: 20px;
    padding-bottom: 8px;
    border-bottom: 1px solid #ccc;

    a { color: black; text-decoration: none;}
  }
}

.item-card {
  min-height: 300px;
  margin-bottom: 60px;
  position: relative;

}


.size-counts, .size-counts li {
  list-style: none;
}
.size-counts {
  margin: 0;
  padding: 0;
}
.size-counts li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 1px solid #eee;
  padding: 3px 0;
  font-size: 14px;
    &.header {
      font-weight: bold;
    }

  span {
    -ms-flex-negative: 0;
        flex-shrink: 0;
  &:first-child {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
  }
  a { color: $llr-blue;}
  }
}

.size-table {
  a {
    color: $llr-blue;
  }
}

.card-link { color: $llr-blue !important;}

.image-trickery {
  position: relative;
  width: 100%;

  .main-style-image {
    position: absolute;
    z-index: 1;
    background-color: #fff;
  }
}

.Reserved img {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}

.Reserved:after {
    position: absolute;
    content: 'Reserved';
    color: #fff;
    background: $llr-grey;
    width: 100%;
    padding: 3px 10px;
    top: 0;
    left: 0;
}

.style-img-col {
  img {
    background-color: #fff;
    padding: 10px;
    margin-bottom: 20px;
  }
}
